<template>
	<div class="legaladvice">
		<div class="legaladvice__navbar">
			<Navbar />
		</div>
		<div class="legaladvice__header" :style="{ 'background-color': primaryColor }">
			<h1 class="legaladvice__header-title">{{ $t('router.legaladvice') }}</h1>
		</div>
		<div class="legaladvice__body" :style="{ 'background-color': 'white' }">
			<div class="legaladvice__body-sections">
				<LegaladviceComponent />
			</div>
		</div>
		<footer class="privacy__footer">
			<Footer />
		</footer>
	</div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue';
import LegaladviceComponent from '@/components/legaladvice/LegalAdviceComponent.vue';
import Footer from '@/components/footer/Footer.vue';

export default {
	name: 'Privacy',
	title() {
		return `${this.$t('router.legaladvice')} | ${this.$store.state.eventInfo.evtrname}`;
	},
	components: {
		Navbar,
		LegaladviceComponent,
		Footer
	},
	data() {
		return {};
	},
	mounted() {},
	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},
	methods: {}
};
</script>

<style lang="scss" scoped>
.legaladvice {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__navbar,
	&__header,
	&__footer {
		flex: none;
	}
	&__body {
		overflow-y: hidden;
		flex: auto;
	}
}

.legaladvice__header {
	background-color: #565567;
	height: 15vh;
	display: flex;
	align-items: center;
}

.legaladvice__header-title {
	font-family: Cabin;
	font-weight: bold;
	font-size: 2rem;
	letter-spacing: 0.0625rem;
	color: #fff;
	padding-left: 10%;
	padding-top: 1.5rem;
}

.legaladvice__body {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.legaladvice__body-sections {
	height: 100%;
	display: flex;
	align-items: top;
	padding-left: 10%;
	padding-right: 10%;
}
</style>
